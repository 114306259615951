const getScrollTarget = () =>
  document.querySelector("#layout-page") || document.body;

let scrollspy;
let scrollspyItems;
let scrollspySections;
let threshold;
let activeMenuItem; // the menu item that is active

const getThreshold = () => {
  // Method to get a threshold between the element and the top of the page
  return (
    document.getElementById("layout-header").getBoundingClientRect().bottom + 16
  );
};

const getSection = (menuItems) => {
  const sections = [];

  menuItems.forEach((item) => {
    sections.push(document.querySelector(item.hash));
  });

  return sections;
};

const scrollspyGoTo = () => {
  const spyLinks = scrollspy.querySelectorAll("a");

  spyLinks.forEach((link) => {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      const target = document.querySelector(event.currentTarget.hash);
      const top =
        target.getBoundingClientRect().top +
        getScrollTarget().scrollTop -
        threshold;

      const scrollTarget = getScrollTarget() || window;

      scrollTarget.scrollTo({ top, behavior: "smooth" });
    });
  });
};

const setMenuActive = (elem) => {
  scrollspyItems.forEach((menuItem) => {
    menuItem.classList.remove("is-active");
  });

  elem.closest("li").classList.add("is-active");
};

const scrollspyActivate = () => {
  if (scrollspy) {
    let visibleSection;
    const scrollPos = getScrollTarget().scrollTop;

    scrollspySections.forEach((section) => {
      // Added 10px for better detection
      if (section.offsetTop <= scrollPos) {
        visibleSection = section;
      }
    });

    const activeSection =
      visibleSection && visibleSection !== null
        ? visibleSection.id
        : scrollspySections[0].id;
    if (activeMenuItem !== activeSection) {
      activeMenuItem = activeSection;
      const menuItem = scrollspy.querySelector(`[href='#${activeMenuItem}']`);
      setMenuActive(menuItem);
    }
  }
};

const initScrollspy = () => {
  scrollspy = document.querySelector(".js-scrollspy");
  if (scrollspy) {
    scrollspyItems = scrollspy.querySelectorAll("li");
    scrollspySections = getSection(scrollspy.querySelectorAll("a"));
    threshold = getThreshold();

    // Start scroll
    getScrollTarget().addEventListener("scroll", scrollspyActivate);
    scrollspyGoTo();
  }
};

export { initScrollspy };
