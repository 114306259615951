/* eslint-disable */

/**
 * Executes a sungle callback or an array of callbacks in different rendered visual frames (async & sequentially)
 * @param {Function | Function[]} callbacks
 */

export const raf = (callbacks) => {
  if (typeof callbacks !== "object") {
    callbacks = [callbacks];
  }

  const run = () => {
    requestAnimationFrame(() => {
      callbacks.shift()();

      if (callbacks.length) run();
    });
  };

  requestAnimationFrame(run);
};
