import { autoAjax } from './panels_ajax_calls';
import SimpleBar from 'simplebar';

const panelEvent = new Event('panel:open');

const initPanels = () => {
  const headerMenuPanelButtons = document.querySelectorAll('.header-menu-entry > [aria-haspopup]');

  const closePanel = (button) => {
    button.classList.remove('is-open');
    button.setAttribute('aria-expanded', false);
  };

  const closeAllPanels = () => {
    headerMenuPanelButtons.forEach(closePanel);
  };

  const openPanel = (button) => {
    button.classList.add('is-open');
    button.setAttribute('aria-expanded', true);
    button.dispatchEvent(panelEvent);
  };

  document.addEventListener('click', (event) => {
    const panelOpen = document.querySelectorAll('.header-menu-entry > [aria-expanded="true"]');
    let targetElement = event.target;

    // Is there one panel open?
    if (panelOpen.length > 0) {
      do {
        if (targetElement.matches('.menu-entry-button') || targetElement.matches('.menu-entry')) {
          // If the click is on the panel, do not close it!
          return;
        }
        // Go up the DOM
        targetElement = targetElement.parentNode;
      } while (targetElement && targetElement !== document);
      // if the click is anywhere but the panel, close it!
      panelOpen.forEach(closePanel);
    }
  });

  const bindPanelOpen = (event) => {
    const button = event.currentTarget;

    if (!button.classList.contains('js-user-card')) {

      const simplebar = new SimpleBar(button.parentElement.querySelector('.js-header-panel'));
      const target = simplebar.getContentElement();
      const scroll = simplebar.getScrollElement();
        autoAjax({
          path: button.dataset.ajaxPath,
          type: button.dataset.ajaxType,
          dataElement: button,
          sourceType: 'panel',
          target: target,
        });

      scroll.addEventListener('scroll', (event) => {

        const targetPanel = event.currentTarget;
        const contentHeight = targetPanel.scrollHeight - targetPanel.clientHeight;

        if (contentHeight <= targetPanel.scrollTop) {
          const button = targetPanel.closest('.header-menu-entry').querySelector('.menu-entry-button');
          if (button.dataset.nextPage !== '0') {
            autoAjax({
              path: button.dataset.ajaxPath,
              type: button.dataset.ajaxType,
              source: 'panel',
              target: targetPanel,
              dataElement: button,
              page: button.dataset.nextPage,
            });
          }
        }
      });
    }
  };

  const bindPanelFocus = (event) => {
    // CLose all open panels
    closeAllPanels();
    openPanel(event.currentTarget);
  };

  const bindPanelClick = (event) => {
    event.currentTarget.focus();
  };

  headerMenuPanelButtons.forEach((button) => {
    button.addEventListener('click', bindPanelClick);
    button.addEventListener('focus', bindPanelFocus);
    button.addEventListener('panel:open', bindPanelOpen, { once: true });
  });

  // Scroll and get more notifications/messages
  const headerMenuPanel = document.querySelectorAll('.js-header-panel');
  headerMenuPanel.forEach((panel) => {
    panel.addEventListener('scroll', (event) => {
      const targetPanel = event.currentTarget;
      const contentHeight = targetPanel.scrollHeight - targetPanel.clientHeight;

      if (contentHeight <= targetPanel.scrollTop) {
        const button = targetPanel.closest('.header-menu-entry').querySelector('.menu-entry-button');
        if (button.dataset.nextPage !== '0') {
          autoAjax({
            path: button.dataset.ajaxPath,
            type: button.dataset.ajaxType,
            source: 'panel',
            target: targetPanel,
            dataElement: button,
            page: button.dataset.nextPage,
          });
        }
      }
    });
  });
};

const incrementCounter = (element) => {
  // element == button
  const headerElement = element;

  // add badge if doens't have
  if (!headerElement.classList.contains('has-badge')) {
    headerElement.classList.add('has-badge');
  }

  const currentCount = Number.parseInt(headerElement.dataset.headerBadge, 10);
  const newCount = currentCount + 1;
  headerElement.dataset.headerBadge = newCount;
};

const decrementCounter = (element) => {
  // element == button
  const headerElement = element;

  const currentCount = Number.parseInt(headerElement.dataset.headerBadge, 10);
  if (currentCount > 0) {
    const newCount = currentCount - 1;
    headerElement.dataset.headerBadge = newCount;

    if (newCount === 0) {
      headerElement.classList.remove('has-badge');
    }
  }
};

window.initPanels = initPanels;

export {
  initPanels,
  incrementCounter,
  decrementCounter,
};
