import Rails from '@rails/ujs';

const cleanErrors = (target) => {
  if (target.querySelector('[data-error]')) {
    target.querySelector('[data-error]').remove();
  }
};

const bindScroll = (event) => {
  const targetElement = event.currentTarget;
  const contentHeight = targetElement.scrollHeight - targetElement.clientHeight;

  if (contentHeight <= targetElement.scrollTop) {
    const path = targetElement.dataset.scrollAppend;
    const nextPage = targetElement.dataset.nextPage || 2;

    if (nextPage !== '0') {
      Rails.ajax({
        type: 'GET',
        url: `${path}?page=${nextPage}&items=10`,
        dataType: 'script',
        accept: 'script',
        success: (response, code, xhr) => {
          const currentPage = Number.parseInt(xhr.getResponseHeader('current-page'), 10);
          const totalPages = Number.parseInt(xhr.getResponseHeader('total-pages'), 10);
          cleanErrors(targetElement);

          targetElement.dataset.nextPage = (currentPage < totalPages) ? currentPage + 1 : 0;
        },
        error: (response, error, xhr) => {
          // In case of an error, show error message saying:
          // "An unexpected error happen. Please try again"
          cleanErrors(targetElement);
          targetElement.insertAdjacentHTML('beforeend', `<div data-error="${xhr.status}" class="p-xs-y-1 p-xs-x-1 text-xs-center font-xs-12 bg-xs-red-100">An unexpected error happen. Please try again!</div>`);
        },
      });
    }
  }
};

const eachScrollable = (element) => {
  element.addEventListener('scroll', bindScroll);
};

const scrollAppend = () => {
  const scrollableElements = document.querySelectorAll('[data-scroll-append]');

  scrollableElements.forEach(eachScrollable);
};

export { scrollAppend };
