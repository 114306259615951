// When changing a page with UJS (remote: true), turbolinks only preserves the latest state
// This method improves that by allowing us to navigate (back/forward buttons) through history
// Everytime we run history.pushState we need to add the following to the state bit
// {path: 'new_path_here'}
// example: history.pushState({path: 'messages/12345'}, '', current_url + '/messages/12345')

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

// regex to get first bit of the path
const regex = /\/[a-zA-Z]+/s;

window.addEventListener('popstate', (event) => {
  const newState = event.state;
  if (newState.path) {
    // if there's NO "path" key, we ignore this and let turbolinks deal with it
    let { path } = newState;
    if (window.originPath.match(regex)[0] === path.match(regex)[0]) {
      // If we're under the same path (eg: '/messages') we deal with it
      path += path.match(/\?/) ? '&' : '?';
      path += 'back=1';
      Rails.ajax({
        url: path,
        type: 'GET',
        cache: false,
        dataType: 'script',
        accept: 'script',
      });
    } else {
      // If we're coming from a different path, Turbolinks is needed
      Turbolinks.visit(path, {
        action: 'restore',
      });
    }
  }
});
