import Rails from '@rails/ujs';

// As the user picture is the only field to be
// submitted in this form we want to submit it on input change
const submitUserPictureOnChange = () => {
  const imgInput = document.getElementById('img-input');
  const imgForm = document.getElementById('edit_client_avatar');
  if (imgInput) {
    imgInput.addEventListener('change', () => {
      Rails.fire(imgForm, 'submit');
    });
  }
}

window.submitUserPictureOnChange = submitUserPictureOnChange;

export { submitUserPictureOnChange }
