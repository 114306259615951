import Rails from '@rails/ujs';
import { decrementCounter } from '../utils/menuPanels';

let messagesPage;
let messagesPanel;

const updateMessageList = (conversationId) => {
  // Change active element/message
  const previousConversation = document.querySelector('.js-conversation-list .b-xs-left-primary-light');
  if (previousConversation !== null) {
    previousConversation.classList.add('b-xs-left-white');
    previousConversation.classList.remove('b-xs-left-primary-light');
  }

  const newConversation = document.querySelector(`#m-${conversationId} div`);
  newConversation.classList.remove('b-xs-left-white');
  newConversation.classList.add('b-xs-left-primary-light');

  const conversationElement = document.querySelector('#layout-message-window');

  conversationElement.scrollTo({
    left: 0,
    top: conversationElement.scrollHeight
  });
  // Add class active to message layout
  messagesPage.classList.add('is-open');


  // Decrease the counter on the header panel if we're opening an unread message
  const newCoversationAvatar = newConversation.querySelector('.avatar');
  if (newCoversationAvatar.classList.contains('has-badge')) {
    newConversation.classList.remove('bg-xs-grey-100', 'b-xs-left-grey-100');
    newCoversationAvatar.classList.remove('has-badge');
    decrementCounter(messagesPanel);

    const panelConversation = document.getElementById(`panel-conversation-${conversationId}`);
    panelConversation.classList.remove('bg-xs-terciary-light');
    panelConversation.querySelector('.avatar').classList.remove('has-badge');
  }
};


const initMessages = () => {
  messagesPage = document.getElementById('layout-message');
  messagesPanel = document.querySelector('[aria-controls="header-menu-messages"]');

  if (messagesPage == null) return;
  // Add to function to window object (accessable via UJS)
  window.updateMessageList = updateMessageList;
  
  const messageItem = document.querySelector('message');
  if (messageItem) {
    Rails.ajax({
      url: `/messages/${messageItem.dataset.id}`,
      type: 'GET',
      dataType: 'script',
      accept: 'script',
    });
  }

  document.addEventListener('keyup', (event) => {
    if (event.target === document.body) {
      // General shortcuts for messages. This is just a prototype, an "easter-egg"
      if (event.keyCode === 67) {
        // Click on "c"
        Rails.ajax({
          url: '/messages/new',
          type: 'GET',
          dataType: 'script',
          accept: 'script',
        });
      }
    } else if (document.querySelector('.js-message-form').contains(event.target)) {
      // Shortucts inside the form
      if (event.target.classList.contains('js-message-editor')) {
        if ((event.metaKey || event.ctrlKey) && event.keyCode === 13) {
          Rails.fire(event.target.closest('form'), 'submit');
        }
      }
    }
  });
};

export { initMessages };
