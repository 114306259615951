export const initTabs = () => {
  const tabsGroup = document.querySelectorAll("[data-tabs]");

  tabsGroup.forEach((tabs) => {
    const buttons = tabs.querySelectorAll("[data-tabs-target]");
    const panels = tabs.querySelectorAll("[data-tabs-id]");

    const setActive = (id) => {
      buttons.forEach((btn) => {
        const btonId = btn.getAttribute("data-tabs-target");
        const targetTab = tabs.querySelector(`[data-tabs-id="${btonId}"]`);

        if (btonId === id) {
          btn.classList.add("is-active");
          targetTab.classList.add("is-active");
        } else {
          btn.classList.remove("is-active");
          targetTab.classList.remove("is-active");
        }
      });

      panels.forEach((panel) => {
        const tabId = panel.getAttribute("data-tabs-id");

        if (tabId === id) {
          panel.classList.add("is-active");
        } else {
          panel.classList.remove("is-active");
        }
      });
    };

    buttons.forEach((button, i) => {
      const targetTab = button.getAttribute("data-tabs-target");

      if (i === 0) {
        setActive(targetTab);
      }

      button.addEventListener("click", () => {
        setActive(targetTab);
      });
    });
  });
};
