// Methods to check if the user is using the platform (HeatBeat)
// Then poll session timeout to check how long until the user is logged out
// if less than sessionTimingToTimeout (default: 2min) we show a popup to extend the session
// if sessionTimingToTimeout === 0, we redirect the user to the login page
let heartBeatActivated = false;
class HeartBeat {
  constructor() {
    const event = (typeof Turbolinks === 'object' && Turbolinks.supported) ? 'turbolinks:load' : 'DOMContentLoaded';
    document.addEventListener(event, () => {
      this.initHeartBeat();
    });
  }

  initHeartBeat() {
    this.lastActive = new Date().valueOf();
    if (!heartBeatActivated) {
      // const eventsToExtendSession = ['click', 'keydown'];

      ['mousemove', 'scroll', 'click', 'keydown'].forEach((activity) => {
        document.addEventListener(activity, (event) => {
          this.lastActive = event.timeStamp + performance.timing.navigationStart;
          // if (eventsToExtendSession.includes(event.type)) {
          //   // TODO: ExtendUserSession on this moment? 
          //   debounce((ExtendUserSession));
          // }
        }, false);
      });
      heartBeatActivated = true;
    }
  }
}

window.heartBeat = new HeartBeat();
