import Tooltip from 'tooltip.js';

const initTooltips = () => {
  const elems = document.querySelectorAll('[data-tooltip]');

  if (elems.length !== 0) {
    elems.forEach((elem) => {
      new Tooltip(elem, {
        position: 'top, bottom',
        title: elem.dataset.tooltip,
        container: document.querySelector('#layout-page')
      });
    })
  }
}

export { initTooltips };