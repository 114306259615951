// Form validation message reset
function handleValidation(status, resetOnly = false) {
  const list = document.getElementsByClassName('js-validation-questions');
  if (list.length === 0) return;

  // Remove any existing error messages
  const formControl = list[0].closest('.form-control');
  formControl.querySelectorAll('[class*="validation-message"]').forEach((a) => {
    a.remove();
  });

  // Return if we only need to reset the validation
  if (resetOnly) return;

  // Add new validation message
  formControl.insertAdjacentHTML(
    'beforeend',
    '<span class="validation-message font-xs-12 font-xs-italic text-xs-red-500">*Mandatory option</span>',
  );
}

// Form validation message
function addValidationMessage(status) {
  if (status === 'disable') return;
  handleValidation(status);
}

// Form validation
function validateElements(status, element) {
  const list = document.getElementsByClassName('js-validation-questions');

  // Validation defaults to true
  let itemChecked = true;
  if (list.length === 0) return itemChecked;

  // Iterate through the options and check if at least one item is selected
  itemChecked = false;
  for (const item of list) {
    if (item.checked) {
      itemChecked = true;
    }
  }

  // If none are selected, disable the button and add message
  if (itemChecked === false) {
    addValidationMessage(status);
    element.checked = false;
  }
  return itemChecked;
}

// Function to change the submit button status (disabled vs enabled)
const toggleAcceptAgreementSubmitButton = (status, element) => {
  // Submit button
  const submitButton = document.getElementById('accept_signature');
  const classesToAddRemove = ['bg-disabled', 'is-disabled'];

  // Form validation
  const itemChecked = validateElements(status, element);
  if (itemChecked === false) return;

  if (status === 'disable' || !status) {
    submitButton.classList.add(...classesToAddRemove);
    submitButton.disabled = true;
  } else if (status || status === 'enable') {
    handleValidation(status, true);
    submitButton.classList.remove(...classesToAddRemove);
    submitButton.disabled = false;
  }
};

// Form checkbox validation
const validationCheckbox = (event) => {
  if (event.checked === true) return;
  const element = document.querySelector('.js-signature-block input');

  const status = element.checked ? 'enable' : 'disable';
  const itemChecked = validateElements(status, element);

  if (itemChecked === true) return;

  const submitButton = document.getElementById('accept_signature');
  const classesToAddRemove = ['bg-disabled', 'is-disabled'];
  submitButton.classList.add(...classesToAddRemove);
  submitButton.disabled = true;
};

const bindChange = (event) => {
  const element = event.target;

  if (element === null || !element.matches('.js-signature-block input')) {
    return;
  }

  if (element.id === 'signature_status') {
    const status = element.checked ? 'enable' : 'disable';
    toggleAcceptAgreementSubmitButton(status, element);
  }
};

document.addEventListener('change', bindChange);
document.addEventListener('input', bindChange);

// Export and make available in the view
export default validationCheckbox;
window.validationCheckbox = validationCheckbox;
