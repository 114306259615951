import Dropzone from 'dropzone';
import MicroModal from 'micromodal';
import confirmCallback from '../utils/confirm_callback';

// Settings
const uploadDocuments = (form, buttons = null, attachments = false) => {
	// Lets check if the form actually exists
	if (form) {
		let allowedContent = document
			.getElementById('allowedContent')
			.value.split(/[ ]+/)
			.join(', ') || [
			'image/jpeg',
			'image/png',
			'image/tiff',
			'image/gif',
			'text/plain',
			'application/rtf',
			'application/x-rtf',
			'text/richtext',
			'text/csv',
			'application/pdf',
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.ms-excel',
			'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.ms-powerpoint',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation',
			'application/vnd.openxmlformats-officedocument.presentationml.template',
			'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
			'application/gzip',
			'application/zip',
			'application/x-zip-compressed',
			'audio/mpeg',
			'audio/webm',
			'audio/mp4',
			'audio/m4a',
			'video/mpeg',
			'video/webm',
			'video/mp4',
			'video/quicktime',
		];

		// Add class to mark it as inited
		form.classList.add('dz-active');

		// Init necessary IDs
		const { id } = form;

		// Set buttons param if null
		if (buttons === null) {
			buttons = `[data-dz-button='${id}']`;
		}

		// Check if the buttons exist
		if (document.querySelectorAll(buttons).length == 0) {
			return;
		}

		// Templating
		const htmlPreviewTemplate = `
      <script type='text/template' id='dz-template-${id}'>
        <li class='dz-preview dz-preview-${id}'>
          <span data-dz-name aria-label='document name'></span>
          <span data-dz-size aria-label='file size'></span>
        </li>
      </script>
    `;

		const htmlMainTemplate = `
      <div class='dz-main' data-dz-main>
        <div class='dz-main-container'>
          <div class='dz-upload-total' aria-hidden='true'>
            <div class='dz-upload-gauge'>
              <i class='icon icon-upload-file' aria-hidden='true'></i>
              <div class='dz-upload-progress' data-dz-total-upload></div>
            </div>
            <div class='dz-uploading-text'>Uploading <span data-dz-upload-text></span></div>
          </div>
          <ul class='dz-upload-list' aria-label='Upload the following documents' data-dz-preview></ul>
        </div>
      </div>
    `;

		// Adding the HTML that dropzone uses for preview
		form
			.querySelector('[data-dz-area]')
			.insertAdjacentHTML('afterend', htmlPreviewTemplate);

		// Adds the main upload template
		form
			.querySelector('[data-dz-area]')
			.insertAdjacentHTML('afterbegin', htmlMainTemplate);
		const dzMain = form.querySelector('[data-dz-main]');

		// Init Dropzone
		const dz = new Dropzone(form, {
			parallelUploads: 10,
			maxFilesize: 64, // MB
			acceptedFiles: allowedContent.toString(),
			clickable: buttons,
			paramName: 'document[file]',
			createImageThumbnails: false,
			previewTemplate: document.querySelector(`#dz-template-${id}`).innerHTML,
			previewsContainer: 'form [data-dz-preview]',
			autoProcessQueue: false,
			headers: {
				Accept: 'application/javascript',
			},
		});

		dz.on('addedfile', () => {
			if (!dzMain.classList.contains('dz-fade-in')) {
				form.addEventListener(
					'animationend',
					() => {
						dz.processQueue();
					},
					{ once: true }
				);

				dzMain.classList.add('dz-fade-in');
			}
		});

		dz.on('sending', (_file) => {
			dzMain.querySelector('[data-dz-upload-text]').innerText = `${
				dz.getUploadingFiles().length
			} ${dz.getUploadingFiles().length > 1 ? 'documents' : 'document'}`;
		});

		dz.on('totaluploadprogress', (progress) => {
			dzMain.querySelector(
				'[data-dz-total-upload]'
			).style.width = `${progress}%`;
		});

		dz.on('error', (file, error) => {
			if (!file.accepted) {
				let message = '';
				if (
					dz.options.maxFilesize &&
					file.size > dz.options.maxFilesize * 1024 * 1024
				) {
					const fileSize = Math.round(file.size / 1024 / 10.24) / 100;
					message = `${file.name} is too big (${fileSize}MB). Max filesize is ${dz.options.maxFilesize}MB.`;
				} else if (!Dropzone.isValidFile(file, dz.options.acceptedFiles)) {
					message = `The file you selected (${file.name}) doesn't have a valid extension.`;
				} else {
					message = error;
				}
				// Remove file and add error message stating why the file can't be added
				dz.removeFile(file);
				dzMain.classList.remove('dz-fade-in');
				confirmCallback(`<i class="icon icon-round-error"></i> ${message}`, {
					targetContainer: document.querySelector('#layout-notifications'),
					classToAdd: 'bg-xs-red-100',
					dismiss: true,
				});
			}
		});

		dz.on('success', (response) => {
			if (dz.getUploadingFiles().length === 0) {
				dzMain.classList.remove('dz-fade-in');
				dzMain.addEventListener(
					'animationend',
					() => {
						try {
							const json = JSON.parse(response.xhr.response);
						} catch (e) {
							// We need Eval to run the returned response given by create.js.erb
							eval(response.xhr.response);
						}
						// On future releases we can use the json format
						// We need to take into account if something fails
						// document.querySelector('.js-documents-list').innerHTML = JSON.parse(response.xhr.response).content;
						dz.removeAllFiles();
						dzMain.classList.remove('dz-fade-out');
					},
					{ once: true }
				);

				dzMain.classList.add('dz-fade-out');
				if (attachments) {
					MicroModal.close();
				}
			}
		});
	}
};

const initDropzone = () => {
	window.uploadDocuments = uploadDocuments;

	// Init dropzone in case there's a need for it
	const dzForms = document.querySelectorAll('.dz');

	dzForms.forEach((dzForm) => {
		// We use this to avoid errors (double inits)
		if (!dzForm.classList.contains('dz-active')) {
			uploadDocuments(dzForm);
		}
	});
};

export { initDropzone };
