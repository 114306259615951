import Rails from '@rails/ujs';
import { trailDate } from './trail_date';

const getTemplate = ({ type, item }) => {
  const senderNameArray = item.sender.name.split(' ');
  const senderInitials = senderNameArray[0][0] + senderNameArray[1][0];

  switch (type) {
    case 'notifications':
      return `<a href="${item.url}" class="row p-xs-x-0-5 p-xs-y-1 b-xs-bottom-1 b-xs-grey-500 text-xs-grey-900 ${item.read ? '' : 'bg-xs-terciary-light' }" role="listitem">
        <div class="col-xs-2 text-xs-center">
          <div class="avatar avatar-xs-40 m-xs-bottom-0-5 ${item.read ? '' : 'has-badge'}" data-avatar-owner="other" role="img" aria-label="${item.sender.name}'s display picture">
            ${item.sender.avatar ? `<img class="image" src="${item.sender.avatar}">` : `<span class="initials" aria-hidden="true">${senderInitials}</span>`}
          </div>
        </div>
        <div class="col-xs-10 p-xs-x-0-5">
          <p class="font-xs-14 ${item.read ? 'text-xs-grey-700' : 'text-xs-grey-900'} m-xs-top-0">${item.text}</p>
          <time datetime="${item.created_at}" class="font-xs-12 font-xs-light">${trailDate(new Date(item.created_at))}</time>
        </div>
      </a>`;
    case 'messages':
      return `<a href="${item.url}" id="panel-conversation-${item.id}" class="row p-xs-x-0-5 p-xs-y-1 b-xs-bottom-1 b-xs-grey-500 text-xs-grey-900 ${item.read ? '' : 'bg-xs-terciary-light'}" role="listitem">
        <div class="col-xs-2 text-xs-center">
          <div class="avatar avatar-xs-40 m-xs-bottom-0-5 ${item.read ? '' : 'has-badge'}" data-avatar-owner="self" role="img" aria-label="${item.sender.name}'s display picture">
            ${item.sender.avatar ? `<img class="image" src="${item.sender.avatar}">` : `<span class="initials" aria-hidden="true">${senderInitials}</span>`}
          </div>
          <div class="font-xs-12 font-xs-light">${item.sender.name}</div>
        </div>
        <div class="col-xs-8 p-xs-x-0-5">
          <h1 class="font-xs-18 font-xs-semibold m-xs-top-0">${item.title}</h1>
          <p class="font-xs-14 text-xs-grey-700">${item.content}</p>
        </div>
        <div class="col-xs-2 text-xs-right">
          <time datetime="${item.created_at}" class="font-xs-12 font-xs-light">${trailDate(new Date(item.created_at))}</time>
        </div>
      </a>`;
    default:
      break;
  }
};

const appendHtml = ({ response = [], type, target }) => {
  // Remove illustration in case there's one
  const svg = target.querySelector('.js-svg-wrapper');
  if (response.length > 0) {
    if (svg) {
      svg.remove();
    }
    response.forEach((item) => {
      target.insertAdjacentHTML('beforeend', getTemplate({ type, item }));
    });
  }
};

const cleanErrors = (target) => {
  if (target.querySelector('[data-error]')) {
    target.querySelector('[data-error]').remove();
  }
};

// autoAjax({path: '/messages', type: 'messages', source:})
const autoAjax = ({ path, type, target, dataElement, page = 1, items = 10, sourceType = 'panel' }) => {
  Rails.ajax({
    type: 'GET',
    url: `${path}?page=${page}&items=${items}`,
    dataType: 'json',
    accept: 'json',
    success: (response, code, xhr) => {
      const currentPage = Number.parseInt(xhr.getResponseHeader('current-page'), 10);
      const totalPages = Number.parseInt(xhr.getResponseHeader('total-pages'), 10);
      cleanErrors(target);

      appendHtml({
        response,
        type,
        target,
      });

      dataElement.dataset.nextPage = (currentPage < totalPages) ? currentPage + 1 : 0;
    },
    error: (response, error, xhr) => {
      // In case of an error, show error message saying:
      // "An unexpected error happen. Please try again"
      cleanErrors(target);
      target.insertAdjacentHTML('beforeend', `<div data-error="${xhr.status}" class="p-xs-y-1 p-xs-x-1 text-xs-center font-xs-12 bg-xs-red-100">An unexpected error happen. Please try again!</div>`)
    },
  });
};

export { autoAjax };
