/* eslint-disable */
import { raf } from "../utils/raf";

const isActive = (target) => target.classList.contains("is-active");

const open = (button, target) => {
  let targetHeight;

  button.classList.add("is-active");

  raf([
    () => {
      target.classList.add("is-active", "is-rendering");
    },
    () => {
      targetHeight = target.getBoundingClientRect().height;
      target.style.setProperty(`--accordion-body-height`, 0);
      target.classList.remove("is-rendering");
      target.classList.add("is-animating");
    },
    () => {
      target.style.setProperty(`--accordion-body-height`, `${targetHeight}px`);

      target.addEventListener(
        "transitionend",
        () => {
          target.classList.remove("is-animating");
          target.style.removeProperty(`--accordion-body-height`);
        },
        { once: true }
      );
    },
  ]);
};

const close = (button, target) => {
  let targetHeight;

  button.classList.remove("is-active");

  raf([
    () => {
      targetHeight = target.getBoundingClientRect().height;
      target.style.setProperty(`--accordion-body-height`, `${targetHeight}px`);
      target.classList.add("is-animating");
    },
    () => {
      target.style.setProperty(`--accordion-body-height`, 0);
    },
    () => {
      target.addEventListener(
        "transitionend",
        () => {
          target.classList.remove("is-animating", "is-active");
          target.style.removeProperty(`--accordion-body-height`);
        },
        { once: true }
      );
    },
  ]);
};

export const initAccordions = () => {
  const buttons = document.querySelectorAll("[data-accordion-target]");

  buttons.forEach((button) => {
    const id = button.getAttribute("data-accordion-target");
    const target = document.querySelector(`[data-accordion-id="${id}"]`);

    button.addEventListener("click", () => {
      if (isActive(target)) {
        close(button, target);
      } else {
        open(button, target);
      }
    });
  });
};
