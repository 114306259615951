import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import debounce from './debounce';

function ExtendUserSession() {
  Rails.ajax({
    url: '/auth/reset_session_timer',
    type: 'GET',
  });
}

const handleTyping = () => debounce((ExtendUserSession));

window.handleTyping = handleTyping;

const sessionTimeoutPollFrequency = 25;
const sessionTimingToTimeout = 2 * 60; // 2min

function pollForSessionTimeout() {
  clearTimeout(window.pollingTimeout);

  //
  if ((Date.now() - window.heartBeat.lastActive) < (sessionTimeoutPollFrequency * 1000)) {
    window.pollingTimeout = setTimeout(pollForSessionTimeout, (sessionTimeoutPollFrequency * 1000));
    return;
  }

  getSessionTimeout()
    .then((response) => {
      response.json().then((data) => {
        const { status } = response;

        if (status === 200) {
          if (data <= 0) {
            window.location.href = '/auth/session_timeout';
          } else {
            window.pollingTimeout = setTimeout(pollForSessionTimeout, (sessionTimeoutPollFrequency * 1000));
          }
        } else if (status === 401) {
          if (data.error === 'automatic_logout') {
            Turbolinks.visit(window.location);
          } else if (data.redirect_to) {
            Turbolinks.visit(data.redirect_to);
          }
        }
      });
    });
}

function getSessionTimeout() {
  const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
  return fetch('/auth/check_session_timeout', {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': csrfToken,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  });
}

document.addEventListener('turbolinks:load', () => {
  clearTimeout(window.pollingTimeout);
  getSessionTimeout().then((response) => {
    response.json().then((data) => {
      let sessionTime = data;
      if (data <= sessionTimingToTimeout) {
        sessionTime = sessionTimeoutPollFrequency + sessionTimingToTimeout;
      }
      window.pollingTimeout = setTimeout(pollForSessionTimeout, (sessionTime - sessionTimingToTimeout) * 1000);
    })});
});
