const fetchAuthToken = async () => {
	const response = await fetch(`//${window.location.host}/cognito-jwt`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	});
	const content = await response.json();

	return content.id_token;
};

export { fetchAuthToken }