const scrollQuestionnaire = (evt, scroll = 'down') => {
  const currElement = evt.target.closest('section');
  const currElementID = parseInt(currElement.getAttribute('data-risk-question'));
  const nextElementID = scroll === 'down' ? currElementID + 1 : currElementID - 1;

  const nextElement = document.querySelector(`[data-risk-question='${nextElementID}']`);
  const topScroll = nextElement.offsetTop;
  const actionBtn = nextElement.querySelector(`[data-risk-button="${scroll === 'down' ? 'previous' : 'next'}"]`);
  const riskContainer = document.querySelector('.js-risk-container');
  document.querySelector("#risk-questions").scrollTo({top: topScroll, behavior: 'smooth'});

  if (actionBtn.classList.contains('opacity-xs-0')) {
    actionBtn.classList.remove('opacity-xs-0', 'is-disabled');
  }
  
  currElement.classList.add('hidden-xs')
  nextElement.classList.remove('hidden-xs')
  nextElement.classList.add('fade-in')
  
  if (!riskContainer.classList.contains('risk-questionnaire-form')) {
    setTimeout(() => riskContainer.classList.add('risk-questionnaire-form'), 1000);
  }
}

const handleImageClick = (images) => {
  const questionnaireHeader = document.querySelector('.js-risk-tolerance-header');
  images.forEach(image => {
    const imageContainer = image.closest('.risk-questionnaire-img-container');
    // create a copy of the image container to insert
    // when we change the position to fixed so we don't break the layout
    const imageContainerCopy = imageContainer.cloneNode(true);
    imageContainerCopy.setAttribute("id", "placeholder-image-container");
    // get the image cointainer icons so we can show/hide them 
    const imageContainerCollapseBtn = imageContainer.querySelector('.js-collapse-questionnaire-img');
    const imageContainerZoomIcon = imageContainer.querySelector('.js-zoom-questionnaire-img');
    image.addEventListener('click', () => {
      questionnaireHeader.classList.remove('z-xs-top');
      imageContainer.classList.add('expanded');
      imageContainerZoomIcon.classList.add('hidden-xs');
      // insert a copy of the image container so we don't break the layout
      imageContainer.insertAdjacentElement('afterend', imageContainerCopy);
    });
    imageContainerCollapseBtn.addEventListener('click', () => {
      questionnaireHeader.classList.add('z-xs-top');
      imageContainer.classList.remove('expanded');
      imageContainerZoomIcon.classList.remove('hidden-xs');
      // remove the copy of the image container added
      document.querySelector('#placeholder-image-container').remove();
    });
  })
}

const initRiskQuestionnaire = () => {
  const riskQuestionnaire = document.getElementById('risk-questions');
  const riskQuestionnaireImages = document.querySelectorAll('.js-risk-questionnaire-img')
  if(riskQuestionnaireImages) handleImageClick(riskQuestionnaireImages)

  if (riskQuestionnaire) {
    const answers = riskQuestionnaire.querySelectorAll('.questionnaire-answer');
    const nextButtons = riskQuestionnaire.querySelectorAll('[data-risk-button="next"]');
    const previousButtons = riskQuestionnaire.querySelectorAll('[data-risk-button="previous"]');
    
    const firstElement = document.querySelector(`[data-risk-question='1']`);
    firstElement.classList.remove('hidden-xs')
    
    answers.forEach( answer => {
      answer.addEventListener('change', (evt) => {

        const question = evt.currentTarget.closest('section');
        const currQuestionAnswers = question.querySelectorAll('.label');
        const questionnaireComplete = question.getAttribute('data-questionnaire-complete');
        const submitButton = question.querySelector('#submit-button');

        if (questionnaireComplete == 'true') {
          submitButton.disabled = false;
          submitButton.classList.remove('is-disabled');
          submitButton.addEventListener('click',() => {
            document.querySelector('.js-loader').classList.remove('hidden-xs');
            submitButton.classList.add('hidden-xs')
          });
        } else {
          scrollQuestionnaire(evt);
        }

        currQuestionAnswers.forEach(currAnswer => {
          currAnswer.classList.add('visited');
        })

      }, false);
    })

    nextButtons.forEach( btn => {
      btn.addEventListener('click', scrollQuestionnaire, false);
    })

    previousButtons.forEach( btn => {
      btn.addEventListener('click', (evt) => {
        scrollQuestionnaire(evt, 'up');
      }, false);
    })

  }
}
export default initRiskQuestionnaire;
window.initRiskQuestionnaire = initRiskQuestionnaire;