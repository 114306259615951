import { initPanels } from "./menuPanels";

const initMenus = () => {
  document.addEventListener("click", (event) => {
    let targetElement = event.target;
    const mobileNavMenu = document.querySelector("#layout-navigation");
    const mobileMainMenu = document.querySelector(".js-main-menu-wrapper");

    do {
      if (targetElement.matches("#layout-main") && mobileNavMenu !== null) {
        // If the click is on the button
        mobileNavMenu.classList.remove("is-open");
        // mobileNavMenu.classList.toggle("hidden-xs-lg");
        return;
      }

      if (targetElement.matches(".js-mobile-navigation-menu") && mobileNavMenu !== null) {
        // If the click is on the button
        mobileNavMenu.classList.toggle("is-open");
        // mobileNavMenu.classList.toggle("hidden-xs-lg");
        return;
      }

      if (targetElement.matches(".js-header-menu-mobile-nav") && mobileMainMenu !== null) {
        mobileMainMenu.classList.toggle("is-open");
        return;
      }

      // Go up the DOM
      targetElement = targetElement.parentNode;
    } while (targetElement && targetElement !== document);
  });

  initPanels();
};

document.addEventListener("DOMContentLoaded", initMenus);
