/* eslint-disable prefer-template */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable object-shorthand */
import confirmCallback from './confirm_callback';
import { fetchAuthToken } from './integrations';

// Calls confirmCallback as an error with a simple message as argument
function showError(message) {
  confirmCallback(`<i class='icon icon-warning font-xs-17 p-xs-right-0-5'></i>${message}`, {
    classToAdd: 'bg-xs-yellow-100',
    targetContainer: document.getElementById('layout-notifications'),
  });
}

/*
  Function that updates the Select address listener
  Making sure that when there is a change in the select the values
  they are either searched again (if it is not an address) 
  or retrieves the address with the correct format
  baseUrl arg is to be fed again to both search and retrieve Address functions
  SelectId is the id of the select fields so we can query only the fields that belong to section being changed
*/
function selectAddress(baseUrl, selectId) {
  const resultList = document.querySelector(
    `.js-result-box[data-select-id="${selectId}"]`,
  );

  // No results
  if (resultList.childNodes.length <= 0) return;

  const selectors = document.querySelectorAll(
    `.js-result-select[data-select-id="${selectId}"]`,
  );

  // Fetch the last selector
  const elem = selectors[selectors.length - 1];
  // IE fix
  elem.onchange = function bar(e) {
    const selectedOption = e.target[e.target.selectedIndex];
    // Get loqate item Type and option value
    const itemType = selectedOption.value.split("__")[0]
    const optionValue = selectedOption.value.split("__")[1]

    if (itemType === 'default') return;
    if (itemType === 'Address') {
      // Remove any additional dropdown
      handleAdditionalDropdown(resultList, e.target);

      retrieveAddress(baseUrl, selectId, optionValue);
    }

    if (itemType == 'Container') {
      searchAddress(baseUrl, selectId, optionValue);
    }
  };
}

// Remove the secondary dropdown if we selected an result - type "Address" on the main dropdown
function handleAdditionalDropdown(selectGroup, triggeredElem) {
  if (selectGroup.childNodes.length == 1) return;

  if (triggeredElem.closest('.choices') === selectGroup.childNodes[0]) {
    removeLastDropdown(selectGroup);
  }
}

/*
  Function that receives the response from the Address Search to loop through the response
  and get add the appropriate options to the select based on the response
  Base Url needs to be passed as an arguments because it needs to be fed back to the search methods
  SelectId is the id of the select fields so we can query only the fields that belong to section being changed
*/
function searchSuccess(response, baseUrl, selectId) {
  const resultBox = document.querySelector(
    `.js-result-box[data-select-id="${selectId}"]`,
  );

  if (resultBox.childNodes.length > 1) {
    removeLastDropdown(resultBox);
  }

  // Render the select Choices element
  const list = document.createElement('select');
  list.setAttribute('class', 'js-select js-result-select');
  list.setAttribute('data-select-id', selectId);
  list.setAttribute('data-placeholder', 'Press to select');
  resultBox.appendChild(list);

  const defaultOption = document.createElement('option');
  defaultOption.innerHTML = 'Select Address';
  defaultOption.setAttribute('value', '');
  defaultOption.setAttribute('selected', 'selected');
  list.appendChild(defaultOption);

  for (let i = 0; i < response.Items.length; i++) {
    const option = document.createElement('option');
    option.setAttribute('value', `${response.Items[i].Type}__${response.Items[i].Id}`);
    option.text = `${response.Items[i].Text} ${response.Items[i].Description}`;
    // Add option to select
    list.appendChild(option);
  }

  window.initChoices([list]);
  selectAddress(baseUrl, selectId, list);
}

/*
  Function that receives the response from the Retrieve Address and 
  add the retrieved info to the appropriate place on the FF
  Base Url needs to be passed as an arguments because it needs to be fed back to the search methods
  SelectId is the id of the select fields so we can query only the fields that belong to section being changed
*/
function retrieveSuccess(response, baseUrl, selectId) {
  const locateSearch = document.querySelector(
    `.js-loqate-element[data-select-id="${selectId}"]`,
  );

  const cardContent = locateSearch.closest('.js-card');
  const addressLine1 = cardContent.querySelector('[name*="address_line1"]');
  const addressLine2 = cardContent.querySelector('[name*="address_line2"]');
  const addressLine3 = cardContent.querySelector('[name*="address_line3"]');
  const addressLine4 = cardContent.querySelector('[name*="address_line4"]');
  const addressLine5 = cardContent.querySelector('[name*="address_line5"]');
  const postcode = cardContent.querySelector('[name*="postcode"]');

  const address = response.Items[0];
  addressLine1.value = address.Line1;
  addressLine2.value = address.Line2;
  addressLine3.value = address.Line3;
  addressLine4.value = address.Line4;
  addressLine5.value = address.City;
  postcode.value = address.PostalCode;
  // Make sure selecting other address still changes values
  selectAddress(baseUrl, selectId);
}

/*
  Function that checks if the postcode query is filled
  and calls the fectchRequest method with the appropriate function argument
  Base Url argument holds the first part of the URL which is sent with its suffix to the fetchRequest
  SelectId is the id of the select fields so we can query only the fields that belong to section being changed
  Container is the ID of a group on loqate, if the user selects an PostCode query (for example) instead of an Address it will do a
second request to request the addresses that belong to that container
*/
function searchAddress(baseUrl, selectId, container = '') {
  const postcodeQuery = document.querySelector(
    `.js-search-address[data-select-id="${selectId}"]`,
  ).value;

  if (postcodeQuery === '') {
    showError('Please enter a Postcode');
    return;
  }

  // Get country selected on country list to limit addresses from that country
  // Only GBR for now
  // const countrySelected = document.getElementById(selectId).value;
  const countrySelected = 'GBR';

  // Create params to be www-form-urlencoded
  const params = new URLSearchParams({
    Text: postcodeQuery,
    Container: container,
    Countries: countrySelected,
  });
  fetchRequest(
    baseUrl + 'loqate/Capture/Interactive/Find/v1.1/json3.ws?',
    params,
    function search(result) {
      searchSuccess(result, baseUrl, selectId);
    },
  );
}

/*
  Function calls fetchRequest is the appropriate function argument to fetchrequest
  Base Url needs to be passed as an arguments because it needs to be fed back to the search methods
  SelectId is the id of the select fields so we can query only the fields that belong to section being changed
  id is the id of the address to retrieve
*/
function retrieveAddress(baseUrl, selectId, id) {
  // Create params to be www-form-urlencoded
  const params = new URLSearchParams({
    Id: id,
  });
  fetchRequest(
    baseUrl + 'loqate/Capture/Interactive/Retrieve/v1.00/json3.ws?',
    params,
    function onSuccess(result) {
      retrieveSuccess(result, baseUrl, selectId);
    },
  );
}

/*
  Function that handles the HTTP request and handles the errors if they happen
  Url is the full URL where to send the request to (URL should end with a question mark "?")
  params is the Encoded www-form-urlencoded arguments needed to do the request
  successMethod is an function object which will be called when the response comes back as ok (200)
*/
async function fetchRequest(url, params, successMethod) {
  fetch(url + params, {
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      AUTHORIZATION: await fetchAuthToken(),
    },
  })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response);
    })
    .then((result) => {
      successMethod(result);
    })
    .catch((error) => {
      if (error.Items !== undefined) {
        if (
          error.Items.length === 1 &&
          typeof error.Items[0].Error !== 'undefined'
        ) { showError(error.Items[0].Description); }
        if (error.Items.length === 0) showError('Sorry, there were no results');
      } else {
        showError('Something went wrong');
      }
    });
}

const renderSearchField = (selectId, button) => {
  const label = document.createElement('LABEL');
  label.innerHTML = 'Search by Postcode';
  // Make attribute specific for this country select2
  label.setAttribute('for', 'loqate-input-' + selectId);
  label.classList.add('m-xs-bottom-1', 'font-xs-12', 'font-md-14');


  const input = document.createElement('input');
  input.type = 'text';
  input.name = 'Address Search';
  input.placeholder = "E.g. 'W1D 7DY'";
  input.id = 'loqate-input-' + selectId;
  input.autocomplete = 'disabled';
  input.classList.add('js-search-address');
  // Make attribute specific for this country select2
  input.setAttribute('data-select-id', selectId);

  // On click, reset the search values and re-enable the search button
  input.addEventListener('click', (event) => {
    event.preventDefault();
    input.value = '';
    const loqateBtn = button;
    loqateBtn.disabled = false;
  });
  return { input, label };
};

const renderBtn = (baseUrl, selectId) => {
  const button = document.createElement('button');
  button.type = 'button';
  button.innerHTML = 'Search';
  button.onclick = function search() {
    searchAddress(baseUrl, selectId);

    // If a search is initiated, disable the search button.
    button.disabled = true;
    // If a search is initiated, remove the previous results.
    const resultsBox = document.querySelector('.js-result-box');
    if (resultsBox) resultsBox.innerHTML = '';
  };
  button.classList.add('btn', 'btn-xs-border-secondary-light', 'text-xs-secondary-dark');
  // Make attribute specific for this country select2
  button.setAttribute('data-select-id', selectId);
  return button;
};

const renderSearchRows = (selectId, searchField, button) => {
  const resultDiv = document.createElement('div');
  resultDiv.setAttribute('class', 'js-result-box');
  resultDiv.setAttribute('data-select-id', selectId);

  // Create input row
  const inputRow = document.createElement('div');
  inputRow.setAttribute('class', 'row w-xs-full bottom-xs');

  // Create input container
  const inputContainer = document.createElement('div');
  inputContainer.setAttribute('class', 'form-section col-xs-7 p-xs-0');
  inputContainer.appendChild(searchField.label);

  // Create form-group
  const formGroup = document.createElement('div');
  formGroup.setAttribute('class', 'form-group');
  // Create form-control
  const formControl = document.createElement('div');
  formControl.setAttribute('class', 'form-control');

  // form-control << input
  formControl.appendChild(searchField.input);
  // form-group << form-control << input
  formGroup.appendChild(formControl);

  // inputContainer << form-group << form-control << input
  inputContainer.appendChild(formGroup);

  // Create button container
  const buttonContainer = document.createElement('div');
  buttonContainer.setAttribute('class', 'col-xs-5 p-xs-left-1');
  buttonContainer.appendChild(button);

  inputRow.appendChild(inputContainer);
  inputRow.appendChild(buttonContainer);

  // Create result row
  const resultsRow = document.createElement('div');
  resultsRow.setAttribute('class', 'w-xs-full m-xs-y-1');
  resultsRow.appendChild(resultDiv);

  return { inputRow, resultsRow };
};

const triggerAddressSearch = (element) => {
  const select = element.closest('select');
  // Get the ID from the select2 to make sure fields only affected by this code relate to this select
  const selectId = select.id;

  // Get the container (valid only on the client side)
  const container = document.querySelector(`#loqate_${selectId}`);
  container.setAttribute('class', 'container-fluid p-xs-0 start-xs m-xs-0');

  // Get the URL for the Loqate access from the dataset which in turns gets it from the ENV file
  const baseUrl = select.dataset.locateUrl;
  // Remove older search elements
  const searchElements = document.querySelectorAll(
    `.js-loqate-element[data-select-id="${selectId}"]`,
  );
  searchElements.forEach((searchElement) => {
    searchElement.remove();
  });

  // Check if the country selected is UK
  if (select.value !== 'GBR') return;

  const newDiv = document.createElement('div');
  const newDivClasses =
    'js-loqate-element form-control m-xs-top-0-5 p-xs-1 bg-xs-grey-300 b-xs-rad-1 row';
  newDiv.setAttribute('class', newDivClasses);
  // Make attribute specific for this country select2
  newDiv.setAttribute('data-select-id', selectId);

  // Render flexbox
  const button = renderBtn(baseUrl, selectId);
  const searchField = renderSearchField(selectId, button);
  const flexRows = renderSearchRows(selectId, searchField, button);

  // Add created elements to show
  newDiv.appendChild(flexRows.inputRow);
  newDiv.appendChild(flexRows.resultsRow);

  // Add new div to after the select container
  container.insertAdjacentElement('afterend', newDiv);
};

/*
  Function to handle event trigger
  It will try to find the closes select, get it's ID and Locate URL from the dataset
  Then it will add the elements needed to do the loqate search while adding the 
  Select's id so those elements are not confused with other's selects loqate search
*/
const triggerAddressSearchEvent = (event) => {
  const clickedElement = event.target;
  triggerAddressSearch(clickedElement);
};

// Remove the last dropdown and its elements from the selectGroup
function removeLastDropdown(selectGroup) {
  selectGroup.lastElementChild.remove();
}

const initLoqate = () => {
  // Inits the event listeners
  var loqateSearchInput = document.querySelector('.js-loqate-search')
  if (!loqateSearchInput) return;

  // Do an initial component render if country is GBR
  triggerAddressSearch(loqateSearchInput)

  // Add event listener to the select
  loqateSearchInput.addEventListener('change', triggerAddressSearchEvent);
}

export { initLoqate };
window.triggerAddressSearch = triggerAddressSearch;
