/*
  Method to get attachments asyncrhonously
*/
import Rails from '@rails/ujs';

const reloadAttachments = (url) => {
  Rails.ajax({
    type: 'GET',
    url,
    dataType: 'script',
    accept: 'script',
  });
};

const initReloadAttachments = () => {
  const attachments = document.querySelectorAll('attachment');

  if (attachments.length > 0) {
    attachments.forEach((attachment) => {
      const url = `/docs/attachments/${attachment.dataset.sourceType}/${attachment.dataset.sourceId}`;
      reloadAttachments(url);
    });
  }

  window.reloadAttachments = reloadAttachments;
};

export { initReloadAttachments };
