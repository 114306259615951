const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}

const isYesterday = (someDate) => {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));

  return someDate.getDate() == yesterday.getDate() &&
    someDate.getMonth() == yesterday.getMonth() &&
    someDate.getFullYear() == yesterday.getFullYear()
}

const pastWeek = (someDate) => {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));
  const oneWeekAgo = new Date(today.setDate(today.getDate() - 7));
  yesterday.setHours(0, 0, 0, 0);
  oneWeekAgo.setHours(0, 0, 0, 0);
  const date = someDate.getTime();

  return date < yesterday && date >= oneWeekAgo;
}

const thisYear = (someDate) => {
  const thisYear = new Date(new Date().getFullYear(), 0, 1);
  thisYear.getTime();
  const date = someDate.getTime();

  return date >= thisYear;
}

const trailDate = (date) => {
  if (date !== undefined) {
    let string = '';
    if (isToday(date)) {
      // example: Today, 10:35 AM
      string = `Today, ${date.toLocaleString('en-US', { hour12:'true', hour:'numeric', minute:'2-digit' })}`
    } else if (isYesterday(date)) {
      // example: Yesterday, 10:35 AM
      string = `Yesterday, ${date.toLocaleString('en-US', { hour12:'true', hour:'numeric', minute:'2-digit' })}`
    } else if (pastWeek(date)) {
      // example: Thursday, 10:35 AM
      string = date.toLocaleString('en-US', {
        weekday: 'long',
        hour12: 'true',
        hour: 'numeric',
        minute: '2-digit'
      })
    } else if (thisYear(date)) {
      // example: 3 February
      string = date.toLocaleString('en-US', {
        day: 'numeric',
        month: 'long'
      })
    } else {
      // example: 3 February 2018
      string = date.toLocaleString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })
    }
    return string
  }
}

export { trailDate }