// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require('@rails/ujs').start();
// require('turbolinks').start();
// require('@rails/activestorage').start();
// require('channels');

// Rails Base
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

// Plugins
import 'flatpickr';
import { initChoices } from './plugins/choices';
import './plugins/micromodal';
import { initDropzone } from './plugins/dropzone';
//import { initAppSignal } from './plugins/app_signal';
import './plugins/simplebar';
import { initDropdown } from './plugins/dropdown';
import { initTooltips } from './plugins/tooltips';

// Utils
import './utils/ajax_popstate';
import './utils/menu';
import { initPanels } from './utils/menuPanels';
import './utils/object_tables';
import './utils/confirm_modal';
import './utils/confirm_callback';
import './utils/form_field_encrypt';
import './utils/masks';
import './utils/heart_beat';
import './utils/sessions';
import './utils/date';
import { initLoqate } from './utils/loqate';
import { submitUserPictureOnChange } from './utils/user_image_uploader';
import { scrollAppend } from './utils/scroll_append';
import { initScrollspy } from './utils/scrollspy';
import { initDynamicToggler } from './utils/dynamic_toggler';
import { initReloadAttachments } from './utils/reload_attachments';
import { initRemoteLinks } from './utils/remote_links';
import { initResendSms } from './utils/resend_sms';

// Views
import { initMessages } from './views/messages';
import {
  initFactFind,
  saveFactFind,
  changeFormStatus,
} from './views/fact_find';
import { initSuitabilityReport } from './views/suitability_report';
import './views/risk_questionnaire';

import './views/fact_find_validation';
import './views/agreements';


Rails.start();
Turbolinks.start();
// EventListeners
document.addEventListener('turbolinks:load', () => {
  window.originPath = window.location.pathname;
  window.confirmCallback = confirmCallback;
  window.initChoices = initChoices;
  window.saveFactFind = saveFactFind;
  window.changeFormStatus = changeFormStatus;
  window.initTooltips = initTooltips;
  window.Rails = Rails;

  initRemoteLinks();
  initMessages();
  initReloadAttachments();
  scrollAppend();
  initDropzone();
  //initAppSignal();
  initPanels();
  initDropdown();

  initScrollspy();
  initDynamicToggler();

  initResendSms();
  initFactFind();
  initRiskQuestionnaire();

  submitUserPictureOnChange();
  initSuitabilityReport();
  initTooltips();
  initLoqate();
});

