// Replaces the default validation message for invalid format
function parseFinalMessage(message) {
  if (/fill/.test(message)) return 'Invalid date format';
  return message;
}

const dateInputKeyUp = (dateElement) => {
  // Check if validation div exists
  const formGroup = dateElement.closest('.form-group') === null
    ? dateElement.parentElement.querySelector('.form-group')
    : dateElement.closest('.form-group');
  const formControl = formGroup.closest('.form-control');

  const target = formControl || formGroup;

  // Reset the div between checks
  const validationContainer = target.querySelector('.validation-message');
  if (validationContainer) validationContainer.remove();

  // Check if the date is valid
  const isValid = dateElement.checkValidity();
  if (isValid) return;

  // Add validation messages
  if (!isValid) {
    target.classList.add('validation-error', 'message-contain');
  }

  if (!isValid) {
    const message = dateElement.validationMessage;
    const finalMessage = parseFinalMessage(message);
    target.insertAdjacentHTML(
      'beforeend',
      `<span class="validation-message font-xs-12 font-xs-italic text-xs-red-500">
        ${finalMessage}
      </span>`,
    );
  }
};

window.dateInputKeyUp = dateInputKeyUp;
