/* eslint-disable */

import Highcharts from "highcharts";

const pluginAttr = "data-pie-chart";
const pluginAttrId = "data-pie-chart-id";
const pluginAttrActive = "data-pie-chart-active";
const pluginAttrColorLabel = "data-pie-chart-color-label";

export const initCharts = () => {
  const pieCharts = Array.from(document.querySelectorAll(`[${pluginAttr}]`));
  pieCharts
    .filter((elem) => elem && !elem.hasAttribute(pluginAttrActive))
    .forEach((elem) => {
      const id = elem.getAttribute(pluginAttrId);
      const colorLabels = Array.from(
        document.querySelectorAll(`[${pluginAttrColorLabel}="${id}"]`)
      );

      const values = elem
        .getAttribute(pluginAttr)
        .split(";")
        .filter((v) => v.length)
        .map((v) => ({
          y: Number(v),
        }));

      const colors = [
        "#9AEDEB",
        "#8286E5",
        "#D791FF",
        "#79CEF2",
        "#B291FF",
        "#F28AB0",
      ];

      Highcharts.setOptions({
        colors,
      });

      const chart = Highcharts.chart(elem, {
        title: { text: null },
        credits: { enabled: false },
        tooltip: { enabled: false },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          width: 250,
          height: 225
        },
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
          pie: {
            innerSize: "50%",
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            states: {
              hover: {
                enabled: false,
              },
            },
            data: values,
          },
        ],
      });

      colorLabels.forEach((label, i) => {
        label.style.setProperty(`--chart-color`, colors[i % colors.length]);
      });

      elem.setAttribute(pluginAttrActive, "");
    });
};
